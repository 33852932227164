import React from "react"

class Footer extends React.Component {
    render() {
        return (
            <footer className="site-footer">
                <div className="footer_inner">
                    <div className="container">
                        <div className="footer-bottom social-right-menu ">
                            <div className="site-info">
                                ©2021 Hola Blanca. Tous droits réservés
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
