import React, { useState, useEffect, useContext } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link, useLocation } from "react-router-dom";
import firebase from "../utils/FirebaseInfo.js";
import { ErrorPage } from "./ErrorPage";
import { promiseWithTimeout, loadData, customLoadData } from "../core/api";
import { useNavigate } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import constants from "../constants/constants";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { CartContext } from "../core/CartProvider";
import { utils } from "../utils";

const IndexPost = ({ data, handleChangeCategory }) => {
  return (
    <React.Fragment>
      <div className="row product-main">
        {data.map(
          (item, index) =>
            item.name !== "Promotions" && (
              <div
                className="Catalogue__item col-sm-12 col-md-6 col-lg-4"
                key={index}
              >
                <a
                  onClick={() =>
                    handleChangeCategory(item.key, `/details/${item.name}`)
                  }
                >
                  <div className="details_List">
                    <div className="image-container">
                      {item.image ? (
                        <img
                          src={item.image}
                          className="product-image"
                          alt={item.name}
                          style={{
                            width: "90%",
                            height: "200px",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <div className="no-image">No Image</div>
                      )}
                      {/* Add background with low opacity */}
                      <div className="image-overlay"></div>
                      {/* Text over the image and overlay */}
                      <div className="image-text">
                        <h2>{item.name}</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            )
        )}
      </div>
    </React.Fragment>
  );
};

const IndexPage = (data) => {
  const [menuList, setMenuList] = useState([]);
  const { getCurrentUser, removeAllItems } = useContext(CartContext);

  const navigate = useNavigate();
  const handleChangeCategory = (selectedCategory, path) => {
    /*setMenuList(
      sousCateg.sousCategories?.filter(
        (subCateg) => subCateg.categorieKey === selectedCategory,
      ),
    );*/
    const currentUser = getCurrentUser();
    if (location.pathname === "/") {
      removeAllItems();
    }
    navigate(
      currentUser && currentUser.tableName ? location.pathname + path : path,
      {
        state: sousCateg.sousCategories?.filter(
          (subCateg) => subCateg.categorieKey === selectedCategory
        ),
      }
    );
  };

  const [menuRefetch, setMenuRefetch] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setMenuRefetch(true);
    categ.refetchCategories();
    sousCateg.refetchSousCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [status, setStatus] = useState(navigator.onLine);

  const customLoadDataLocal = async (type, path, withDataOfKey, dataOfKey) => {
    if (type === "categorie") setCateg({ ...categ, isLoadingCategories: true });
    if (type === "config")
      setSousCateg({ ...sousCateg, isLoadingSousCategories: true });
    if (!status) {
      if (type === "categorie")
        setCateg({
          ...categ,
          isLoadingCategories: false,
          isRefetchingCategories: false,
          isErrorCategories: true,
        });
      if (type === "config")
        setSousCateg({
          ...sousCateg,
          isLoadingSousCategories: false,
          isRefetchingSousCategories: false,
          isErrorSousCategories: true,
        });
      setMenuRefetch(false);
      throw new Error("Connection error");
    }
    const res = await promiseWithTimeout(
      customFetch({ path, withDataOfKey, dataOfKey, type }),
      10000
    );
    return res;

    //console.log("--------\nData of "+path+" : ",res)
  };

  const [sousCateg, setSousCateg] = useState({
    sousCategories: [],
    isLoadingSousCategories: false,
    isErrorSousCategories: false,
    isRefetchingSousCategories: false,
    refetchSousCategories: () =>
      customLoadDataLocal("config", "/CategorieConfig"),
  });

  const [categ, setCateg] = useState({
    categories: [],
    isLoadingCategories: false,
    isErrorCategories: false,
    isRefetchingCategories: false,
    refetchCategories: () => customLoadDataLocal("categorie", "/Categories"),
  });

  useEffect(() => {
    if (
      sousCateg.sousCategories &&
      sousCateg.sousCategories.length > 1 &&
      categ.categories &&
      categ.categories[0]?.key
    ) {
      setMenuRefetch(false);
    }
  }, [categ.categories, sousCateg.sousCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  const location = useLocation();

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setStatus(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [status]);

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);

  const { updateCurrentUser } = useContext(CartContext);
  const [displayForm, setDisplayForm] = useState(false);

  const currentUser = getCurrentUser();

  useEffect(() => {
    if (
      !(
        currentUser &&
        currentUser.phone &&
        currentUser.name &&
        currentUser.firstName &&
        currentUser.tableName
      )
    ) {
      setDisplayForm(false);
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  function isNumeric(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  {
    /*begin load categorie */
  }
  const customFetch = ({ path, withDataOfKey, dataOfKey, type }) => {
    return firebase.ref(path).on(
      "value",
      async (snapshot) => {
        try {
          if (type === "categorie") {
            let data = [];
            snapshot.forEach((childsnap) => {
              if (withDataOfKey && dataOfKey) {
                const dataKey = childsnap.key;
                childsnap.forEach((subChildsnap) => {
                  let val = subChildsnap.val();
                  val[dataOfKey] = dataKey;
                  val["key"] = subChildsnap.key;
                  data.push(val);
                });
              } else {
                let val = childsnap.val();
                val["key"] = childsnap.key;
                data.push(val);
              }
            });
            setCateg({
              ...categ,
              categories: data,
              isLoadingCategories: false,
              isRefetchingCategories: false,
            });

            return data;
          }
          if (type === "config") {
            console.log("======== reload CategorieConfig");
            loadData("/CategorieConfig").then(async (categoriesConfig) => {
              let newSousCategories = [];
              let tempSousCategories = await customLoadData(
                "/SousCategories",
                true,
                "categorieKey"
              );
              //console.log('\nbefore categoriesConfig ', JSON.stringify(categoriesConfig));

              //console.log('\nbefore ', tempSousCategories);
              tempSousCategories?.map((sousCategorie) => {
                if (sousCategorie?.customChoice) {
                  let newConfigArray = sousCategorie?.customChoice?.map(
                    (customChoice: any) => {
                      return categoriesConfig?.filter((catConfig) => {
                        return customChoice === Object.keys(catConfig)[0];
                      });
                    }
                  );
                  sousCategorie.customChoice = newConfigArray;
                }

                newSousCategories.push(sousCategorie);
              });
              //console.log('\n after ', newSousCategories);
              setSousCateg({
                ...sousCateg,
                sousCategories: newSousCategories,
                isLoadingSousCategories: false,
                isRefetchingSousCategories: false,
              });
              return newSousCategories;
            });
          }

          const currentUser = getCurrentUser();
          setDisplayForm(
            !(
              currentUser &&
              currentUser.phone &&
              currentUser.name &&
              currentUser.firstName &&
              currentUser.tableName
            ) &&
              location.pathname.split("/")[1] === constants.urlqrcode &&
              location.pathname.split("/")[3] === constants.suburlqrcode &&
              isNumeric(location.pathname.split("/")[2])
          );

          /*
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui' style={{padding:'20px', textAlign:'center'}}>
                    <h1>HOLA 🤗</h1>
                    <p>Vous etes sur la table <b>{location.pathname.split("/")[2]}</b></p>
                    <form>
                      <label>
                        Numéro de téléphone
                        <input type="number" name="phone" onChange={(e)=>setPhone(e.target.value)}/>
                      </label>
                      <label>
                        Nom complet
                        <input type="text" value={name} name="name" onChange={(e)=>setName(e.target.value)}/>
                      </label>

                      <button disabled={phone.length<=0 || name.length<=0 || phoneError || nameError} 
                      onClick={()=>{
                        updateCurrentUser({
                          phone,
                          name
                        })
                        onClose()
                      }} style={{margin:5,border: 'none',padding: 15}}>Confirmer</button>
                    </form>
                    
                  </div>
                );
              },
              closeOnClickOutside: true
            })*/
        } catch (error) {
          if (type === "categorie")
            setCateg({
              ...categ,
              isLoadingCategories: false,
              isRefetchingCategories: false,
              isErrorCategories: true,
            });
          if (type === "config")
            setSousCateg({
              ...sousCateg,
              isLoadingSousCategories: false,
              isRefetchingSousCategories: true,
              isErrorSousCategories: true,
            });
          setMenuRefetch(false);
        }
      },
      (error) => {
        if (type === "categorie")
          setCateg({
            ...categ,
            isLoadingCategories: false,
            isRefetchingCategories: false,
            isErrorCategories: true,
          });
        if (type === "config")
          setSousCateg({
            ...sousCateg,
            isLoadingSousCategories: false,
            isRefetchingSousCategories: true,
            isErrorSousCategories: true,
          });
        setMenuRefetch(false);
      }
    );
  };

  return (
    <Layout>
      <div className="container" style={{ paddingTop: 3 }}>
        <div className="text-center mt-5"></div>
        {!status ? (
          <ErrorPage subtitle="Merci de vérifier votre accès à internet" />
        ) : displayForm ? (
          <div
            className="custom-ui"
            style={{ padding: "20px", textAlign: "center" }}
          >
            <h1>HOLA 🤗</h1>
            <h3>
              Vous êtes
              {location.pathname.split("/")[2] === "0" ? (
                " au comptoir"
              ) : (
                <>
                  {" "}
                  sur la table{" "}
                  <span
                    style={{
                      lineHeight: 1.5,
                      backgroundColor: "rgb(208, 60, 57)",
                      borderRadius: 20,
                      padding: 10,
                      color: "white",
                    }}
                  >
                    {location.pathname.split("/")[2]}
                  </span>
                </>
              )}
            </h3>
            <div>
              <div style={{ marginBottom: 20 }}>
                <label>
                  <p style={{ marginBottom: 5 }}>Numéro de téléphone</p>
                  <input
                    placeholder="0xxxxxxxxx ..."
                    style={{
                      borderWidth: 1,
                      padding: 8,
                      borderRadius: 10,
                      borderColor: "#80808045",
                    }}
                    type="number"
                    value={phone}
                    name="phone"
                    onChange={(e) => {
                      utils.validatePhoneNumber(e.target.value, setPhoneError);
                      setPhone(e.target.value);
                    }}
                  />
                  {phoneError && (
                    <p
                      style={{
                        fontSize: "small",
                        marginTop: "5px",
                        color: "#d03c39",
                        marginBottom: 5,
                      }}
                    >
                      {phoneError}
                    </p>
                  )}
                </label>
              </div>
              <div style={{ marginBottom: 20 }}>
                <label>
                  <p style={{ marginBottom: 5 }}>Nom complet</p>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginBottom: 15 }}>
                      <input
                        placeholder="Nom ..."
                        style={{
                          borderWidth: 1,
                          padding: 8,
                          borderRadius: 10,
                          borderColor: "#80808045",
                        }}
                        type="text"
                        value={name}
                        name="name"
                        onChange={(e) => {
                          utils.validateUsername(e.target.value, setNameError);
                          setName(e.target.value);
                        }}
                      />
                      {nameError && (
                        <p
                          style={{
                            fontSize: "small",
                            marginTop: "5px",
                            color: "#d03c39",
                            marginBottom: 5,
                          }}
                        >
                          {nameError}
                        </p>
                      )}
                    </div>

                    <div>
                      <input
                        placeholder="Prénom ..."
                        style={{
                          borderWidth: 1,
                          padding: 8,
                          borderRadius: 10,
                          borderColor: "#80808045",
                        }}
                        type="text"
                        value={firstName}
                        name="firstName"
                        onChange={(e) => {
                          utils.validateUsername(
                            e.target.value,
                            setFirstNameError
                          );
                          setFirstName(e.target.value);
                        }}
                      />
                      {firstNameError && (
                        <p
                          style={{
                            fontSize: "small",
                            marginTop: "5px",
                            color: "#d03c39",
                            marginBottom: 5,
                          }}
                        >
                          {firstNameError}
                        </p>
                      )}
                    </div>
                  </div>
                </label>
              </div>

              <button
                disabled={
                  phone.length <= 0 ||
                  name.length <= 0 ||
                  firstName.length <= 0 ||
                  phoneError ||
                  nameError ||
                  firstNameError
                }
                onClick={() => {
                  updateCurrentUser({
                    phone,
                    name,
                    firstName,
                    tableName: location.pathname.split("/")[2],
                  });

                  setDisplayForm(false);
                }}
                style={{
                  width: "80%",
                  margin: 5,
                  borderRadius: 10,
                  border: "none",
                  padding: 15,
                }}
              >
                Continuer
              </button>
            </div>
          </div>
        ) : menuRefetch ||
          categ.isLoadingCategories ||
          sousCateg.isLoadingSousCategories ? (
          <div
            style={{
              height: "800px",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <PuffLoader size={150} color="#b63119" />
          </div>
        ) : sousCateg.isErrorSousCategories || categ.isErrorCategories ? (
          <ErrorPage />
        ) : (
          <IndexPost
            data={categ.categories}
            handleChangeCategory={handleChangeCategory}
          />
        )}
      </div>
    </Layout>
  );
};

export default IndexPage;
