import { COLORS, FONTS } from "./theme";


const screens = {
  menu: 'Menu',
  commande: 'Commandes',
  menuJour: 'Offre HOLA',
  notifications: 'Notifications'
};

const bottom_tabs = [
  {
    id: 0,
    label: screens.menu,
  },
  {
    id: 1,
    label: screens.commande,
  },
  {
    id: 2,
    label: screens.menuJour,
  },
  {
    id: 3,
    label: screens.notifications,
  },
];

const delivery_time = [
  {
    id: 1,
    label: '10 Mins',
  },
  {
    id: 2,
    label: '20 Mins',
  },
  {
    id: 3,
    label: '30 Mins',
  },
];

const ratings = [
  {
    id: 1,
    label: 1,
  },
  {
    id: 2,
    label: 2,
  },
  {
    id: 3,
    label: 3,
  },
  {
    id: 4,
    label: 4,
  },
  {
    id: 5,
    label: 5,
  },
];

const tags = [
  {
    id: 1,
    label: 'Burger',
  },
  {
    id: 2,
    label: 'Fast Food',
  },
  {
    id: 3,
    label: 'Pizza',
  },
  {
    id: 4,
    label: 'Asian',
  },
  {
    id: 5,
    label: 'Dessert',
  },
  {
    id: 6,
    label: 'Breakfast',
  },
  {
    id: 7,
    label: 'Vegetable',
  },
  {
    id: 8,
    label: 'Taccos',
  },
];

const track_order_status = [
  {
    id: 1,
    title: 'Commande reçue',
    sub_title: 'Votre commande est bien reçue',
  },
  {
    id: 2,
    title: 'Commande confirmée',
    sub_title: 'Votre commande est confirmée',
  },
  {
    id: 3,
    title: 'Commande en préparation',
    sub_title: 'Votre commande est en cours de préparation',
  },
  {
    id: 4,
    title: 'Commande en route',
    sub_title: 'Votre commande est en route',
  },
  {
    id: 5,
    title: 'Commande livrée',
    sub_title: 'Bon appétit !',
  },
  {
    id: 6,
    title: 'Commande annulée',
    sub_title: 'Votre commande est annulé !',
  } /*,
  {
    id: 5,
    title: 'Rate Us',
    sub_title: 'Help us improve our service',
  },*/,
];

const urlqrcode = "oobCodecEhlMYKGZRO6yxmx-Ok3_SAejAkFrKvqUxNEr3z7k2gAAAGClu79LA"
const suburlqrcode = "22oobCodecEhlMYKGZRO6yxmx-Ok-343_SAejAkFrKvqUxNEr3z7k2gAAAGClu79LA"

export default {
  screens,
  bottom_tabs,
  delivery_time,
  ratings,
  tags,
  track_order_status,
  urlqrcode,
  suburlqrcode
};
