import {COLORS, SIZES} from '../../constants/theme';
import React from 'react';


const IconButton = ({icon, onPress, iconStyle, contentStyle}) => {
  return (
    <button
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: SIZES.radius,
        width: 35,
        height: 35,
        borderColor: COLORS.gray2,
        ...contentStyle,
      }}
      onClick={onPress}>
      <img src={icon} style={iconStyle} />
    </button>
  );
};

export default IconButton;
