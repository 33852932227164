import React, {useContext, useState} from 'react';
import {SIZES, FONTS, COLORS, icons} from '../../constants/theme';
import { Gradient } from 'react-gradient';
import {CartContext} from '../../core/CartProvider';
import information from '../../icons/information.png'
import TextButton from '../TextButton';


const FooterTotal = ({
  onConfirm,
  isLoading,
  disabled,
  onAddNewItem,
  labelConfirm,
  displayExtraFees,
  handleChange,
  isRadio
}) => {
  const {getTotalPrice} = useContext(CartContext);

  const [displayOrderType, setDisplayOrderType] = useState(false)

  return (
    <div>
    
    <div
      style={{
        display:'flex',
        flexDirection: 'column',
        paddingBottom: SIZES.base,
        position:'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        padding:15,
        backgroundColor:'rgb(245 245 245)',
        borderTopRightRadius:20,
        borderTopLeftRadius:20,
        boxShadow: '#4f4f4f47 1px 0px 2px 1px'
      }}>
        {
          !displayOrderType ?

        <div>
        {/* total */}
        <div>
          <div style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between'
            }}>
          <p style={{flex:1,marginBottom:5,textAlign:'left'}}>
            Total
          </p>
          <p style={{flex:2,marginBottom:5,textAlign:'right'}}>
            {getTotalPrice().toFixed(2)} Dhs 
          </p>
          </div>
          {displayExtraFees && getTotalPrice() < 50 &&<p
            style={{
              display:'flex',
              flex: 2,
              textAlign: 'right',
              marginTop:4
            }}>
            (+ 5 Dhs)
          </p>}
          
        </div>
        {displayExtraFees && getTotalPrice() < 50 &&
        <div style={{
          marginTop:5,
          backgroundColor:'#f7f5f5',
          borderRadius:20,
          padding:5,
          display:'flex',
          flexDirection:'row',
          alignItems:'center'
        }}>
          <img src={information} style={{height: 25,
            width: 25,
            tintColor:  COLORS.gray}}/>
        <p
            style={{
              textAlign: 'center',
              fontSize:11,
              maxWidth:'92%',
              padding:2,
              lineHeight: 18
            }}>
              Atteignez un montant de 5O Dhs et économisez les frais de livraison de 5 Dhs 
          </p>
        </div>
        }
        {/* button */}
        <TextButton
          isLoading={isLoading}
          disabled={disabled}
          buttonContainerStyle={{
            width:'100%',
            height: 60,
            marginTop: 5,
            borderRadius: SIZES.radius,
            backgroundColor:
              isLoading || disabled
                ? COLORS.transparentPrimary
                : COLORS.primary,
          }}
          label={labelConfirm || 'Confirmer ma commande'}
          onPress={()=>setDisplayOrderType(true)}
        />
        
        </div>
        :
        <div>
        <p style={{textAlign:'center', marginBottom:5}}>Merci de choisir le type de votre commande</p>
        <div style={{display:'flex',flexDirection:'row',alignContent:'center',justifyContent:'center'}}>
            <TextButton
              buttonContainerStyle={{
                height: 60,
                marginTop: 5,
                borderRadius: SIZES.radius,
                width:"50%",
                backgroundColor:'#0f5132'
              }}
              label={'Commande à table'}
              onPress={()=>{
                handleChange('surplace')
                onConfirm('surplace')
              }}
            />
              

            <TextButton
              buttonContainerStyle={{
                height: 60,
                marginTop: 5,
                borderRadius: SIZES.radius,
                width:"50%",
                marginLeft:10,
                backgroundColor:'#0f5132'
              }}
              label={'Commande à emporter'}
              onPress={()=>{
                handleChange('selfdelivery')
                onConfirm('selfdelivery')
              }}
            />
            
        </div>
        </div>
        }
        {!isLoading &&
        <TextButton
          buttonContainerStyle={{
            height: 60,
            borderRadius: SIZES.radius,
            backgroundColor: COLORS.transparent,
          }}
          labelContainerStyle={{
            color: COLORS.primary,
            textAlign: 'center',
          }}
          label="Pas encore ! Je veux ajouter un autre produit"
          onPress={onAddNewItem}
        />}
      </div>
    </div>
  );
};

export default FooterTotal;
