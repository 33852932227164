import {COLORS, FONTS, icons, SIZES} from '../../constants/theme';
import React from 'react';
import minus from "../../icons/minus.png"
import plus from "../../icons/plus.png"
import IconButton from "../IconButton"



const StepperInput = ({containerStyle, onMinus, onPlus, value}) => {
  return (
    <div
      style={{
        display:'flex',
        flexDirection: 'row',
        height: 60,
        width: SIZES.width * 0.3,
        backgroundColor: '#dee2e6',
        borderRadius: SIZES.radius,
        alignItems: 'center',
        justifyContent: 'center',
        padding:10,
        ...containerStyle,
      }}>
      {onMinus && (
        <IconButton
          icon={minus}
          onPress={onMinus}
          iconStyle={{
            height: 25,
            width: 25,
            tintColor: value > 1 ? COLORS.primary : COLORS.gray,
            marginTop:3
          }}
          contentStyle={{borderWidth: 0}}
        />
      )}
      <div
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          margin: 15,
          marginTop: 35
        }}>
        <p style={{...FONTS.h3}}>{value}</p>
      </div>
      {onPlus && (
        <IconButton
          icon={plus}
          onPress={onPlus}
          iconStyle={{height: 25, width: 25, tintColor: COLORS.primary,marginTop:3}}
          contentStyle={{borderWidth: 0}}
        />
      )}
    </div>
  );
};

export default StepperInput;
