import {COLORS, SIZES} from '../../constants/theme';
import React, {useContext} from 'react';
import {CartContext} from '../../core/CartProvider';
import { FaShoppingBag } from 'react-icons/fa';

const CartQuantityButton = ({containerStyle, onPress}) => {
  const {getItemsCount} = useContext(CartContext);

  return (
    <button
      style={{
        width: 40,
        height: 40,
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: COLORS.black,
        border:'none',
        ...containerStyle,
      }}
      onClick={onPress}>
      <FaShoppingBag color="white" size={30}/>
      {getItemsCount() > 0 && (
        <div
          style={{
            position: 'absolute',
            top: 2,
            right: 4,
            height: 18,
            width: 18,
            backgroundColor: COLORS.primary,
            borderRadius: SIZES.radius,
            display:'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <p
            style={{
              color: COLORS.white,
              fontSize: 14,
              marginTop: 27,
              fontWeight:'bold'
            }}>
            {getItemsCount()}
          </p>
        </div>
      )}
    </button>
  );
};

export default CartQuantityButton;
