import {COLORS, FONTS, SIZES} from '../constants/theme';
import constants from '../constants/constants'
import React, {useContext, useState, useEffect} from 'react';
import {LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
  } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import firebase from "../utils/FirebaseInfo.js";
import {promiseWithTimeout} from '../core/api';
import {useMutation} from 'react-query';
import MyComponent from 'react-fullpage-custom-loader'




import StepperInput from "../components/StepperInput"
import {CartContext} from '../core/CartProvider';
import {utils} from '../utils';
import { ErrorPage } from '../pages/ErrorPage';
import emptystore from "../images/empty_store.png"
import { useNavigate, Link } from 'react-router-dom';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FooterTotal from '../components/FooterTotal/FooterTotal';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TextButton from '../components/TextButton';




const MyCart = () => {
  const {items, updateQuantityHandler, removeItem} = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(false);

  const [formattedItemsList, setFormattedItemsList] = useState([]);
  const {removeAllItems} = useContext(CartContext);
  const {getTotalPrice, getCurrentUser} = useContext(CartContext);
  const currentUser = getCurrentUser()

  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction onClick={() => console.info('swipe action triggered')}>
        Action name
      </SwipeAction>
    </LeadingActions>
  );
  
  const trailingActions = () => (
    <TrailingActions>
      <SwipeAction
        destructive={true}
        onClick={() => console.info('swipe action triggered')}
      >
        Delete
      </SwipeAction>
    </TrailingActions>
  );
 

  const styles = {
    cartItemContainer: {
      display:'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: SIZES.radius,
      paddingHorizontal: SIZES.radius,
      borderRadius: SIZES.radius,
    },
  };

  const getCustomKey = (item) => {
    console.log("============ item ",item)
    let newKey = item.product.name+item.product?.comment;
    item.product?.categorieConfig?.map((catConf) => {
      newKey += catConf.value;
    });
    return newKey;
  };
  const [status, setStatus] = useState(true);


  useEffect(() => {
    window.scrollTo(0, 0)
    function changeStatus() {
      setStatus(navigator.onLine);
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);



  const countCustomChoice = (item) => {
    let count = 0
    item?.product?.categorieConfig?.map(
      (catConfig, index) => 
       catConfig?.value?.map((catConf)=>count++)
    )
    console.log("===========count ",count)
    return count
  }

  const navigate = useNavigate();


  const fetchTokens = () => {
    return firebase
      .ref('/Users')
      .once('value')
      .then((snapshot) => {
        let data = [];
        snapshot.forEach((subChildsnap) => {
          if (subChildsnap.val().role === 'admin' || subChildsnap.val().role === 'cuisinier')
            data.push(subChildsnap.val().token);
        });
        return data;
      });
  };

  const sendNotif = async () => {
    const tokens = await fetchTokens();
    const currentDate = await utils.fetchCurrentTime();
    const message = {
      registration_ids: tokens,
      notification: {
        title: 'Nouvelle commande',
        body:
          "Une nouvelle commande d'un montant de " +
          getTotalPrice() +
          ' Dhs est lancée à ' +
          utils.formatDate(currentDate),
        vibrate: 1,
        sound: 1,
        show_in_foreground: true,
        priority: 'high',
        content_available: true,
      },
    };

    let headers = new Headers({
      'Content-Type': 'application/json',
      Authorization:
        'key=	AAAALnUKSnQ:APA91bEHYzu0sL95BGqzwKingY-YyIgyFK_F0xyPNxzY3wkpNojff40hKbRZbGEv9b3nVwb8RdgWc3CyfaU2DbVdfo2u3L4wuwH4JLwv1NjxP-3BjgpUiY_iQ_gEf3ITAtWZB8jK3Xzp',
    });
    //console.log('message sent from rest call', message);
    try {
      let response = await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers,
        body: JSON.stringify(message),
      });
      response = await response.json();
      console.log('response ', response);
    } catch (error) {
      console.log('error ', error);
    }
  };



  function onDeleteAll() {
    //console.log("Deleted all history");
  }
  
  function deleteAllHistory() {
   /*
    let deletingAll = chrome.history.deleteAll();
    deletingAll.then(onDeleteAll);*/
  }
  
  deleteAllHistory();

  const updateUserMutation = useMutation(
    async (data) =>
    {
      
      if(!status){
        //Alert.alert("Aucune connexion internet","Veuillez vérifier votre accès à internet.")
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui' style={{padding:'20px', textAlign:'center'}}>
                <h1>Oups!</h1>
                <p>Veuillez vérifier votre accès à internet.</p>
                <button onClick={onClose} style={{margin:5,border: 'none',padding: 15}}>Annuler</button>
              </div>
            );
          },
          closeOnClickOutside:false
        })
        throw new Error("Connection error")
      }
      console.log("=========oooooooooh")
      return promiseWithTimeout(Promise.resolve(firebase.ref('/Orders').push(data)),10000);

    },
    
    {
      onSuccess: async () => {
        console.log("==success")
        deleteAllHistory();
        removeAllItems();
        await sendNotif();
        setIsLoading(false);
       
        navigate('/');
        
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui' style={{padding:'20px', textAlign:'center'}}>
                <h1>Commande reçue</h1>
                <p>Veuillez scanner à nouveau le QR code si vous voulez passer une nouvelle commande</p>
                <p>On vous contactera par téléphone en cas de besoin</p>
                <h3>Keep in touch 😉</h3>
                <button
                  style={{margin:5,border: 'none',padding: 15}}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Consultez notre menu
                </button>
              </div>
            );
          },
          closeOnClickOutside:false
        })
        
      },
      onError: error => {
        setIsLoading(false);
        console.log(
          '=============error updating user phone address ...',
          error,
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui' style={{padding:'20px', textAlign:'center'}}>
                <h1>Oups!</h1>
                <p style={{marginBottom:5}}>Un problème est survenu.</p>
                <p>Veuillez vérifier votre accès à internet et réessayer ultérieurement</p>
                <button onClick={onClose} style={{margin:5,border: 'none',padding: 15}}>OK</button>
              </div>
            );
          },
          closeOnClickOutside:false
        })
      },
    },
  );


  const [isRadio, setIsRadio] = useState(null);

  const handleChange = (e) => {
    // string passed in
    // a string returned by default
    console.log(e);
    // add + to the event to make the value a number
    setIsRadio(e);
  };


  const renderSwiperList = () => {
    return (
      <>
        {items.length === 0 ? (
          <div style={{display:'flex',flex:1}}>
              <ErrorPage 
              resetError={()=>navigate("/"+constants.urlqrcode + "/"+currentUser.tableName+"/"+constants.suburlqrcode)}
              image={emptystore}
              title="Oups !"
              subtitle="Votre panier est vide"
              buttonLabel={"Consultez notre menu"}/>
          </div>
        ) : (
            <SwipeableList>
                {
                    items?.map((item)=>
                    <SwipeableListItem key={getCustomKey(item)} style={{
                        marginTop: SIZES.radius,
                        paddingBottom: SIZES.padding * 2,
                        display:'block'
                    }}>
                        <div
                            style={{
                            paddingLeft: SIZES.padding,
                            paddingRight: SIZES.padding,
                            minHeight: (item.product?.comment ? 155 : 115) + countCustomChoice(item)*10,
                            backgroundColor: COLORS.lightGray2,
                            width:'100%',
                            ...styles.cartItemContainer
                            }}>
                            <div
                            style={{
                                width: '30%',
                                height: 100,
                                marginLeft: -5,
                            }}>
                            {item.product.image ?
                                <img
                                src={item.product.image}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit:'contain'
                                }}
                                />
                            :
                                <div></div>
                            }
                            </div>

                            <div
                            style={{
                                flex: 1,
                                flexDirection:'column'
                            }}>
                            <div style={{display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                            <p style={{width:'45%',marginBottom:0}}>{item.product.name}</p>
                            <StepperInput
                            containerStyle={{
                                height: 50,
                                width: SIZES.width * 0.3,
                                backgroundColor: COLORS.white,
                            }}
                            value={item.qty}
                            onPlus={() =>
                                updateQuantityHandler(item.qty + 1, item.product)
                            }
                            onMinus={() => {
                                if (item.qty > 1) {
                                updateQuantityHandler(item.qty - 1, item.product, true);
                                }else{
                                    confirmAlert({
                                      customUI: ({ onClose }) => {
                                        return (
                                          <div className='custom-ui' style={{padding:'20px', textAlign:'center'}}>
                                            <h1>Attention</h1>
                                            <p>Êtes-vous sûr de vouloir supprimer ce produit ?</p>
                                            <button onClick={onClose} style={{margin:5,border: 'none',padding: 15}}>Annuler</button>
                                            <button
                                              style={{margin:5,border: 'none',padding: 15}}
                                              onClick={() => {
                                                removeItem(item.product);
                                                onClose();
                                              }}
                                            >
                                              Confirmer
                                            </button>
                                          </div>
                                        );
                                      },
                                      closeOnClickOutside:false
                                    })
                                /*Alert.alert(
                                    'Attention',
                                    'Êtes-vous sûr de vouloir supprimer ce produit ?',
                                    [
                                    {
                                        text: 'Confirmer',
                                        onPress: async () => {
                                        removeItem(item.product)
                                        },
                                    },
                                    {
                                        text: 'Annuler',
                                        onPress: () => console.log('OK Pressed'),
                                        style: 'cancel',
                                    },
                                    ],
                                );*/
                                
                                }
                            }}
                            />
                            </div>
                            <p style={{color: COLORS.primary,marginBottom:0}}>
                                {item.product.price} Dhs
                            </p>
                            <div>
                            <p style={{ fontSize:11}}>{utils.displayExtrasItem(item.product.categorieConfig)}</p>
                            
                            {
                                item?.product?.comment?.length > 0 && 
                                <p style={{marginTop:-5, fontSize:10, fontStyle:'italic',textTransform:'uppercase'}}>
                                    * {item.product?.comment}
                                </p>
                            }
                            </div>
                            
                            
                            </div>

                            
                        </div>
                    </SwipeableListItem>)
                }
          {/*<SwipeListView
            data={items}
            keyExtractor={(item) => `${getCustomKey(item)}`}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{
              marginTop: SIZES.radius,
              paddingBottom: SIZES.padding * 2,
              paddingHorizontal: SIZES.padding
            }}
            disableRightSwipe={true}
            rightOpenValue={-75}
            renderItem={({item}) => (
              <div
                style={{
                  minHeight: (item.product.comment ? 155 : 115) + countCustomChoice(item)*10,
                  backgroundColor: COLORS.lightGray2,
                  ...styles.cartItemContainer
                }}>
                <div
                  style={{
                    width: 90,
                    height: 100,
                    marginLeft: -5,
                  }}>
                  {item.product.image ?
                    <img
                      src={item.product.image}
                      style={{
                        width: '85%',
                        height: '85%',
                        position: 'absolute',
                        objectFit:'contain'
                      }}
                    />
                  :
                    <img src={paperbag} style={{height: '85%', width: '85%',tintColor:'#706f6f',position: 'absolute',objectFit:'contain'}}/>
                  }
                </div>

                <div
                  style={{
                    flex: 1,
                  }}>
                    <div style={{flexDirection:'row', alignItems:'center'}}>
                  <p style={{...FONTS.h3,width:'40%'}}>{item.product.name}</p>
                  <StepperInput
                  containerStyle={{
                    height: 50,
                    width: SIZES.width * 0.3,
                    backgroundColor: COLORS.white,
                  }}
                  value={item.qty}
                  onPlus={() =>
                    updateQuantityHandler(item.qty + 1, item.product)
                  }
                  onMinus={() => {
                    if (item.qty > 1) {
                      updateQuantityHandler(item.qty - 1, item.product, true);
                    }else{
                      Alert.alert(
                        'Attention',
                        'Êtes-vous sûr de vouloir supprimer ce produit ?',
                        [
                          {
                            text: 'Confirmer',
                            onPress: async () => {
                              removeItem(item.product)
                            },
                          },
                          {
                            text: 'Annuler',
                            onPress: () => console.log('OK Pressed'),
                            style: 'cancel',
                          },
                        ],
                      );
                      
                    }
                  }}
                />
                  </div>
                  <p style={{color: COLORS.primary, ...FONTS.h3}}>
                    {item.product.price} Dhs
                  </p>
                  <div>
                <p style={{...FONTS.body5, fontSize:11,lineHeight:19}}>{utils.displayExtrasItem(item.product.categorieConfig)}</p>
                  
                  {
                    item?.product?.comment?.length > 0 && 
                    <p style={{...FONTS.body5, marginTop:-5, fontSize:10, fontStyle:'italic',textTransform:'uppercase'}}>
                          * {item.product.comment}
                    </p>
                  }
                  </div>
                  
                  
                </div>

                
              </div>
            )}
            renderHiddenItem={data => (
              <IconButton
                contentStyle={{
                  flex: 1,
                  justifyContent: 'flex-end',
                  backgroundColor: 'red',
                  width: '100%',
                  ...styles.cartItemContainer,
                }}
                icon={icons.delete_icon}
                iconStyle={{
                  marginRight: 10,
                  width: 35,
                  height: 35,
                }}
                onPress={() => removeItem(data.item.product)}
              />
            )}
          />*/}
          </SwipeableList>
        )}
      </>
    );
  };

  return (
    <div
      style={{
        backgroundColor: COLORS.white,
        marginBottom:300
      }}>
      {
        isLoading ? <MyComponent sentences={["Merci de patienter ..."]}/> :
      <>
    
      {renderSwiperList()}
     
      {items.length > 0 && (
        <>
                
        <FooterTotal
          isLoading={isLoading}
          disabled={isLoading}
          handleChange={handleChange}
          isRadio={isRadio}
          labelConfirm={'Finaliser ma commande'}
          onAddNewItem={() => navigate("/"+constants.urlqrcode + "/"+currentUser.tableName+"/"+constants.suburlqrcode)}
          onConfirm={(isRadio) => {
            
            
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui' style={{padding:'20px', textAlign:'center'}}>
                    <h1>Attention</h1>
                    <p>Êtes-vous sûr de vouloir confirmer cette commande ? </p>
                    <p>Aucune modification ne peut être appliquée sur votre commande après confirmation.</p>
                    {
                      isRadio === 'surplace' ? <p style={{backgroundColor:'#0f5132',padding:5,color:'white',borderRadius:20}}>Commande à table{currentUser.tableName === "0" ? " depuis le comptoir" : " N° "+currentUser.tableName}</p> : <p style={{backgroundColor:'#0f5132',padding:5,color:'white',borderRadius:20}}>Commande à emporter depuis {currentUser.tableName === "0" ? "le comptoir" : "la table N° "+currentUser.tableName}</p>
                    }
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                      <button onClick={onClose} style={{margin:5,border: 'none',padding: 15, borderRadius:10, border: '0.5px solid #0000003b'}}>Vérifier ma commmande</button>
                      <button
                      style={{margin:5,border: 'none',padding: 15, borderRadius:10, border: '0.5px solid #0000003b'}}
                      onClick={async () => {
                        console.log("==============hola")
                        setIsLoading(true);
                        onClose();
                        const currentDate = await utils.fetchCurrentTime();


                        let newItemsList = [];
                        console.log("================= itemssss ",JSON.stringify(items))
                        items.forEach((element) => {
                          let newElement = {};
                          newElement['totalPrice'] = element.totalPrice;
                          newElement['product'] = {
                            categorieConfig: element.product?.categorieConfig || [],
                            name: element.product.name,
                            price: element.product.price,
                            qty: element.qty
                          };
                          newItemsList.push(newElement);
                        });

                        let orderData = {
                          userUID: "GUEST",
                          createdAt: JSON.stringify(currentDate),
                          totalPrice: getTotalPrice(),
                          items: newItemsList,
                          phoneNumber:currentUser.phone,
                          address: "HOLA Blanca",
                          statut: constants.track_order_status[0],
                          userName:currentUser.name+" "+currentUser.firstName,
                          email:"guest@gmail.com",
                          delivery:{
                            deliveryType:isRadio,
                            tableNumber:currentUser.tableName
                          }
                          
                        }
                        updateUserMutation.mutate(orderData);
                        
                      }}
                    >
                      Confirmer ma commande
                    </button>
                    </div>
                  </div>
                );
              },
              closeOnClickOutside:false
            })
            
          }}
        />

        {



        }
        </>
        )}
        </>
      }
    </div>
  );
};


const MyCartContainer = () => {
  const {getCurrentUser} = useContext(CartContext)
  const currentUser = getCurrentUser()
  const top = (currentUser && currentUser.tableName) ? 145 : 80

  return  <Layout withFooter={false} hideAction={true}>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div className="container">
  
    <div className="text-center mt-5 sub_header" style={{paddingHorizontal:'5px',paddingTop:3,top:top}}>
    
<h4 className="" style={{fontWeight: 400,
color: "rgb(102, 102, 102)",marginBottom:0,padding:10}}>

<Link to={(currentUser && currentUser.tableName) ? "/"+constants.urlqrcode + "/"+currentUser.tableName+"/"+constants.suburlqrcode : "/"} style={{
      color: "rgb(102, 102, 102)",
      fontWeight: "600",
      textDecoration: "none",
      marginRight: "10px",
 
      
}}>{/*<img src={back} style={{height: '25px', width: '25px',tintColor:'#666666',borderRadius:20,objectFit:"contain",marginBottom:'5px'}}/>*/}
 RETOUR AU MENU</Link>/ Mon panier</h4>
</div>
      <MyCart/>
    </div>
    
 </Layout>
}


export default MyCartContainer;
