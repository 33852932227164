import {COLORS} from '../../constants/theme';
import {FONTS} from '../../constants/theme';
import React, {useState} from 'react';
import './style.css';



const CustomRadioButton = ({data, updateSelectedConfigValues}) => {
  const [selectedValues, setSelectedValues] = useState([]);

  const isSelected = (value) => {
    let isSelected = false

    selectedValues?.map((selectedValue)=>{
      if(selectedValue.value === value)
        isSelected = true
    })
    return isSelected
  }
 
  return (
    <div
      style={{
        backgroundColor: COLORS.lightGray2,
        borderRadius: 10,
        padding: 10,
        marginTop: 10,
      }}>
                {/*<p>data {JSON.stringify(data[0])}</p>
        <p>keys {JSON.stringify(Object.keys(data[0]))}</p>
      <p>{JSON.stringify(selectedValues)}</p>*/}
      {data.length> 0 && Object.keys(data[0]).map((key, index) => {
        return (
          <div key={index}>
            <div style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between',
              marginBottom:data[0][key]?.description?.length > 0 ? 0 : 10,
              marginTop: 5,
              alignItems:'center',
              alignContent:'center',
              marginBottom:-20
            }}>
            <p
              style={{
                color: COLORS.black,
                width:data[0][key].required ? '70%' : '100%'
              }}>
              {data[0][key].title}
            </p>
            {
              data[0][key].required && 
              <h5
              style={{
                color: COLORS.white,
                backgroundColor:COLORS.primary,
                borderRadius:10,
                padding:5,
                textAlign:'center'
              }}>
              Choix obligatoire
            </h5>
            }
            </div>
            {data[0][key]?.description?.length > 0 && <p
              style={{
                color: COLORS.darkGray2,
                marginBottom: 10,
              }}>
              {data[0][key].description}
            </p>}
            {data[0][key]?.data?.map((currValue) => (
              <button
                style={{
                  borderRadius: 10,
                  borderWidth: 0.1,
                  borderColor: "#33333357",
                  marginBottom: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}

                onClick={() => {
                  
                  let customChoiceArray = []

                    setSelectedValues((prevItems )=>{
                      const item = prevItems.find(
                        (item) =>{              
                          return item.value === currValue.value
                        }
                      );
                      if(data[0][key].maxItems > selectedValues.length)
                      {
                        
                        if(item){
                          customChoiceArray = prevItems.filter((currentItem)=>currentItem.value !== currValue.value)
                          updateSelectedConfigValues(key, customChoiceArray, data[0][key]?.required);
                          return customChoiceArray
                        }
                        customChoiceArray = [
                          ...prevItems.filter((currentItem)=>currentItem.value !== currValue.value),
                          currValue
                        ]
                        updateSelectedConfigValues(key, customChoiceArray, data[0][key]?.required);

                        return customChoiceArray
                      }else{
                        if(item){
                          customChoiceArray = prevItems.filter((currentItem)=>currentItem.value !== currValue.value)
                          updateSelectedConfigValues(key, customChoiceArray, data[0][key]?.required);

                          return customChoiceArray
                        }
                        customChoiceArray = prevItems
                        updateSelectedConfigValues(key, customChoiceArray, data[0][key]?.required);

                        return customChoiceArray
                      }
                    })
                }}
                key={currValue.value + '_' + key}
                class="container">
                <p
                  style={{
                    margin:'auto',
                    padding:10,
                    color: (data[0][key].maxItems > selectedValues.length || isSelected(currValue.value)) ? COLORS.darkGray : COLORS.lightGray1,
                  }}>
                  {currValue.value} {currValue.price>0 && ( '+' + currValue.price + 'Dhs')}
                </p>
                
                <div class="radioCircle" style={{borderColor: (data[0][key].maxItems > selectedValues.length || isSelected(currValue.value)) ? '#5e5253' : COLORS.lightGray1}}>
                  {selectedValues?.map((selectedValue)=>{
                    return selectedValue.value === currValue.value && <span class="checkmark">
                    <div class="checkmark_stem"></div>
                    <div class="checkmark_kick"></div>
                </span>
                  })
                  }
                </div>
              </button>
                ))}
          </div>
        );
      })}
    </div>
  );
};

export default CustomRadioButton;
