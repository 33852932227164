import React, { useEffect, useState, useContext } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import back from "../icons/back.png"
import { CartContext } from "../core/CartProvider";
import constants from "../constants/constants";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { utils } from "../utils";



const IndexCategory = ({data,onOpenModal}) => {
  const navigate = useNavigate();
  const {getCurrentUser} = useContext(CartContext)
  const currentUser = getCurrentUser()
  const location = useLocation()
  return (
      <React.Fragment>
        
        <div className="row product-main">
          {data.map((item,index) => (
            <a onClick={async ()=>{
              const start = 7 * 60;
              const end = 24 * 60;

              const endDej = 13 * 60;

              const date = await utils.fetchCurrentTime();
              const now = date.getHours() * 60 + date.getMinutes();

              if ((currentUser && currentUser.phone && currentUser.name && currentUser.firstName && currentUser.tableName) && (start > now || now > end)){
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className='custom-ui' style={{padding:'20px', textAlign:'center'}}>
                        <h1>HOLA 🤗</h1>
                        <p>Désolé ! Votre commande ne peut être traité en dehors des horaires de livraison </p>
    
                          <button
                          onClick={()=>{
                            onClose()
                          }} style={{margin:5,border: 'none',padding: 15}}>J'ai compris !</button>
                        
                      </div>
                    );
                  },
                  closeOnClickOutside: false
                })
              }
              else{
                if(item.categorieKey === "CategorieUID94" ){
                 
                  if (start > now || now > endDej)
                    confirmAlert({
                      customUI: ({ onClose }) => {
                        return (
                          <div className='custom-ui' style={{padding:'20px', textAlign:'center'}}>
                            <h1>HOLA 🤗</h1>
                            <p>Nos petits déjeuners ne sont servis que dans la matinée</p>
        
                              <button
                              onClick={()=>{
                                onClose()
                              }} style={{margin:5,border: 'none',padding: 15}}>J'ai compris !</button>
                            
                          </div>
                        );
                      },
                      closeOnClickOutside: false
                    })
                  else{
                    currentUser && currentUser.phone && currentUser.name && currentUser.firstName && currentUser.tableName &&
                    navigate("/"+constants.urlqrcode + "/"+currentUser.tableName+'/foodDetail/'+constants.suburlqrcode,{state:item})  
                  }
                }else{
                  console.log("currentUser ",currentUser)
                  currentUser && currentUser.phone && currentUser.name && currentUser.firstName && currentUser.tableName &&
                  navigate("/"+constants.urlqrcode + "/"+currentUser.tableName+'/foodDetail/'+constants.suburlqrcode,{state:item})
                }
              }
              
              }}
              className="Catalogue__item col-sm-12 col-md-6 col-lg-4"
              key={index}
            >
              <div>
                <div className="details_List">
                  {item.image ? (
                    <img src={item.image}  style={{width:'90%',height:'200px',objectFit:'contain'}}/>                    
                  ) : (
                    <div className="no-image">Image bientôt disponible</div>
                  )}

                  <div className="details_inner">
                    <h2>{item.name}</h2>
                    {
                      item.description &&
                      <div>
                        <p><div style={{padding:10,color:"#636363"}} dangerouslySetInnerHTML={{ __html: item.description }} /></p>
                      </div>
                    }
                    {
                    item.price !== 0 &&
                      <div>
                        <hr style={{width:"40%",margin:"auto",marginBottom:10,height:0.1}}></hr>

                        <div className="row">
                          <div className="col-sm-12 align-self-center">
                            <span className="price">{item.price} Dhs</span>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </a>
          ))}
         </div>
      </React.Fragment>
  )
}

const CategoryPage = () => {

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  let { categ } = useParams();
  const {state} = useLocation();
  const {getCurrentUser} = useContext(CartContext)
  const currentUser = getCurrentUser()
  const top = (currentUser && currentUser.tableName) ? 145 : 80
  return <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    
    <div className="container" >

      <div className="text-center mt-5 sub_header" style={{paddingTop:3,top:top}}>
    
        <h4 className="" style={{fontWeight: 400,
    color: "rgb(102, 102, 102)",marginBottom:0,padding:10}}>
      
      
      <Link to={(currentUser && currentUser.tableName) ? "/"+constants.urlqrcode + "/"+currentUser.tableName+"/"+constants.suburlqrcode : "/"} style={{
              color: "rgb(102, 102, 102)",
              fontWeight: "600",
              textDecoration: "none",
              marginRight: "10px",
 
        }}>{/*<img src={back} style={{height: '25px', width: '25px',tintColor:'#666666',borderRadius:20,objectFit:"contain",marginBottom:'5px'}}/>*/}
          RETOUR AU MENU</Link>/ {categ}</h4>
      </div>
      <IndexCategory data={state}/>
    </div>
  </Layout>
}

export default CategoryPage
