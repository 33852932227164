import firebase from "firebase/app"
import "firebase/database";

const FirebaseInfo = {
  apiKey: 'AIzaSyAKJ5YVzizYTAHN9Abh1ew8PUzyEnjqIpA',
  appId: '1:199532104308:android:5cfbc0b3053f7714fadf21',
  messagingSenderId: '199532104308',
  projectId: 'holablanca-delivery',
  databaseURL: 'https://holablanca-delivery-default-rtdb.firebaseio.com/',
  storageBucket: 'holablanca-delivery.appspot.com',
};

firebase.initializeApp(FirebaseInfo);
export default firebase.database();