import firebase from "../utils/FirebaseInfo.js";

const fetch = ({path}) => {
  return firebase
    .ref(path)
    .once('value')
    .then((snapshot) => {
      let data = [];
      snapshot.forEach((subChildsnap) => {
        let jsonData = {};
        jsonData[subChildsnap.key] = subChildsnap.val();
        data.push(jsonData);
      });
      return data;
    });
};

const customFetch = ({path, withDataOfKey, dataOfKey}) => {
  return firebase
    .ref(path)
    .once('value')
    .then((snapshot) => {
      let data = [];
      snapshot.forEach((childsnap) => {
        if (withDataOfKey && dataOfKey) {
          const dataKey = childsnap.key;
          childsnap.forEach((subChildsnap) => {
            let val = subChildsnap.val();
            val[dataOfKey] = dataKey;
            val['key'] = subChildsnap.key;
            data.push(val);
          });
        } else {
          let val = childsnap.val();
          val['key'] = childsnap.key;
          data.push(val);
        }
      });
      return data;
    });
};

/*
const checkConnection = () => {
  return db
    .database()
    .ref(".info/connected")
    .once('value')
    .then((snapshot) => {
      console.log("info/connected ",snapshot.val())
      if(snapshot.val() === false){
        throw new Error("Connection error")
      }
      return snapshot.val();
    })
};*/

function promiseWithTimeout(
  promise,
  ms,
  timeoutError = new Error('Promise timed out')
) {
  // create a promise that rejects in milliseconds
  const timeout = new Promise((_, reject) => {
    setTimeout(() => {
      reject(timeoutError);
    }, ms);
  });
  // returns a race between timeout and the passed promise
  return Promise.race([promise, timeout]);
}

const customLoadData = async (
  path,
  withDataOfKey,
  dataOfKey,
) => {
  /*const stateConnection = await NetInfo.fetch();
  if(!stateConnection.isConnected){
    //Alert.alert("Aucune connexion internet","Veuillez vérifier votre accès à internet.")
    throw new Error("Connection error")
  }*/

  const res = await promiseWithTimeout(customFetch({path, withDataOfKey, dataOfKey}),10000);
  //console.log("--------\nData of "+path+" : ",res)
  return res;
};

const loadData = async (path: string) => {

  /*const stateConnection = await NetInfo.fetch();
  if(!stateConnection.isConnected){
    //Alert.alert("Aucune connexion internet","Veuillez vérifier votre accès à internet.")
    throw new Error("Connection error")
  }*/


  //const connection = await checkConnection();

  const res = await promiseWithTimeout(fetch({path}),10000);
  //console.log('--------\nData of ' + path + ' : ', res);

  return res;
};

export {customLoadData, loadData, promiseWithTimeout};
