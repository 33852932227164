import PropTypes from "prop-types"
import React, { useContext } from "react"
import "../css/font-awesome.css"
import "bootstrap/dist/css/bootstrap.css"
import "../css/style.css"
import logo from "../images/cafe-logo.png"
import { CartContext } from "../core/CartProvider"
import CartQuantityButton from "./cartQuantityButton/CartQuantityButton"
import { useNavigate, useLocation } from "react-router-dom"
import constants from "../constants/constants"

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



const Header = ({ siteTitle }) => { 
  const {getCurrentUser} = useContext(CartContext);
  const navigate = useNavigate()
  const currentUser = getCurrentUser()
  const location = useLocation()

  const height = (currentUser && currentUser.phone && currentUser.name && currentUser.firstName && currentUser.tableName) ? 145 : 80

  return (
  <header className="site-header" style={{height:height}}>
    <div className="container">
      <div className="row" style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
        <div className="col-7 col-sm-6 col-md-4 align-self-center" >
          <a className="header-logo" href="/"><img src={logo} alt="Hola Blanca logo"></img></a>
        </div>
        <div>
       
            
        </div>
        
        {
            !location?.pathname?.toLocaleLowerCase().includes("mycart") && (currentUser && currentUser.phone && currentUser.name && currentUser.firstName && currentUser.tableName)
            &&
            <>
         
            <div style={{position:'absolute',right:'3%',width:'auto',top:'19px'}}>
              <CartQuantityButton onPress={() => {
                navigate("/"+constants.urlqrcode + "/"+currentUser.tableName+"/MyCart/"+constants.suburlqrcode  )
              }}/>
            </div>
            </>
        }
        
        
      </div>
       {(currentUser && currentUser.phone && currentUser.name && currentUser.firstName && currentUser.tableName) &&
          <span style={{border:'0.2px solid #ffffff3d', marginTop:10,textAlign:'center', fontWeight:'bold', borderRadius: 20, color:'white',padding:10, display:'block'}} onClick={()=>confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className='custom-ui' style={{padding:'20px', textAlign:'center'}}>
                      <h1>HOLA 🤗</h1>
                      <p>Veuillez scanner à nouveau le QR code si vous avez changé la table</p>
                     
  
                        <button
                        onClick={()=>{
                          onClose()
                        }} style={{margin:5,border: 'none',padding: 15}}>J'ai compris !</button>
                      
                    </div>
                  );
                },
                closeOnClickOutside: false
              })}>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <p style={{marginBottom:0}}>HOLA 🤗 {currentUser.firstName.toUpperCase()} {currentUser.name.toUpperCase()}</p>
                <p style={{marginBottom:0}}> {currentUser.tableName === "0" ? "Comptoir" : "Table N° " + currentUser.tableName}</p>
                </div>
          </span>
          }
    </div>

  </header >
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
