
  
  function formatDate(d) {
    const mounths = [
      'Janv.',
      'Fév.',
      'Mars',
      'Avr.',
      'Mai',
      'Juin',
      'Juil.',
      'Août',
      'Sept.',
      'Oct.',
      'Nov.',
      'Déc.',
    ];
    const days = ['Dim.', 'Lun.', 'Mar.', 'Merc.', 'Jeu.', 'Ven.', 'Sam.'];
    return (
      days[d.getDay()] +
      ' ' +
      d.getDate() +
      ' ' +
      mounths[d.getMonth()] +
      ' ' +
      d.getFullYear() +
      ' [ ' +
      d.getHours() +
      ':' +
      d.getMinutes() +
      ' ]'
    );
  }
  
  const fetchCurrentTime = async () => {
    try {
      let response = await fetch('http://worldtimeapi.org/api/timezone/Africa/Casablanca');
      let json = await response.json();
      //let d = new Date(JSON.parse("2022-06-03T17:26:57.606Z"))
      let d = new Date(json.datetime)
      //console.log("ddddddddd ",d)
      //console.log("new Date().getTimezoneOffset() ",new Date().getTimezoneOffset())
      //d.setTime( d.getTime() - new Date().getTimezoneOffset()*60*1000 );
      //console.log('json.currentDateTimeyyy; ', json.datetime, d);
      return d;
    } catch (error) {
      console.error('error fetchCurrentTime ', error);
      return new Date();
    }
  };
  
  
  const displayExtrasItem = (categorieConf) => {
    let extrasItem = []
  
    let countExtra = 0
    categorieConf?.map(
      (catConfig, index) => 
      {
       catConfig?.value?.map((catConf,currIndex)=> {
        countExtra++
      })
      }
    )
  
    categorieConf?.map(
      (catConfig, index) => 
      {
      return catConfig?.value?.map((catConf,currIndex)=> {
      
        extrasItem.push(extrasItem.length+1 === countExtra ? catConf : (catConf+", "))
      })
      }
    )
    return extrasItem
  }
  
  const typeOfDelivery = (deliveryInfo) => {
    if(deliveryInfo?.deliveryType === 'domicile')
      return "| À domicile"
    if(deliveryInfo?.deliveryType === 'selfdelivery')
      return "| Self service"
    if(deliveryInfo?.deliveryType === 'surplace')
      return "| À table N° "+deliveryInfo.tableNumber
  }

  function validatePhoneNumber(value, setUsernameError) {
    var pattern = /^\d+$/;
    if (value.length != 10 || !pattern.test(value) || (value.substring(0,2) !== '06' &&  value.substring(0,2) !== '07')) {
      setUsernameError('Numéro de téléphone invalide. Ex: 0611223344');
    } else {
      setUsernameError('');
    }
  }

  function validateUsername(value, setUsernameError) {
    if (value.length < 3) {
      setUsernameError('Un minimum de 3 caractères est requis');
    } else {
      setUsernameError('');
    }
  }
  
  const utils = {
    formatDate,
    fetchCurrentTime,
    displayExtrasItem,
    typeOfDelivery,
    validatePhoneNumber,
    validateUsername
  };
  
  export default utils;
  