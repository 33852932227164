import React from 'react';
import {COLORS, FONTS} from '../../constants/theme';


const TextButton = ({
  onPress,
  label,
  buttonContainerStyle,
  labelContainerStyle,
  disabled,
  label2,
  isLoading,
}) => {
  return (
    <button
      onClick={onPress}
      disabled={disabled}
      style={{
          border:'none',
          display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:
          isLoading || disabled ? COLORS.transparentPrimary : COLORS.primary,
        ...buttonContainerStyle,
      }}>
      {!isLoading ? (
        <h5
          style={{
            color: COLORS.white,
            marginTop:'revert',
            ...labelContainerStyle,
          }}>
          {label}
        </h5>
      ) : (
        <div style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          {/*<ActivityIndicator size={20} color={COLORS.white} />*/}
          <p
            style={{
              color: COLORS.white,
              marginTop:'revert',
              ...labelContainerStyle,
              marginLeft: 10,
            }}>
            {label}
          </p>
        </div>
      )}
      {label2 && (
        <h5
          style={{
            flex: 1,
            color: COLORS.white,
            textAlign: 'right',
            marginTop:'revert',
            ...labelContainerStyle,
          }}>
          {label2}
        </h5>
      )}
    </button>
  );
};

export default TextButton;
