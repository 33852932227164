import React, { useEffect, useState, useContext } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import paperbag from "../icons/paper-bag.png"
import back from "../icons/back.png"

import { SIZES, COLORS, FONTS } from "../constants/theme";
import CustomRadioButton from "../components/customRadioButton/customRadioButton";
import StepperInput from "../components/StepperInput"
import TextButton from "../components/TextButton"
import { CartContext } from "../core/CartProvider";
import constants from "../constants/constants";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const IndexFoodDetail = ({item}) => {
  const navigate = useNavigate();

  const {addItemToCart, getCurrentUser} = useContext(CartContext);

  const currentUser = getCurrentUser()
  
  const [selectedValues, setSelectedValues] = useState([]);
  const [qty, setQty] = useState(1);

  const getNewPrice = () => {
    let newPrice = Number(item.price)

    selectedValues.map((element)=>{
                element.value.map((value)=>{
                  newPrice += Number(value.price)
                })
    })

    return newPrice
  }


  const countOfRequiredReached = () => {
    const countOfRequiredSelected = selectedValues?.filter((selectedValue)=>{
      return selectedValue?.required && selectedValue?.value?.length >0
    })?.length

    let countOfRequiredMustBeSelected = 0
    item?.customChoice?.map((customChoice)=>{
      console.log("======== customChoice ",customChoice)
      customChoice.length > 0 && Object.keys(customChoice[0]).map((key, index) => {
        customChoice[0][key].required && (countOfRequiredMustBeSelected++)
      })
    })
    return countOfRequiredSelected === countOfRequiredMustBeSelected
  }


  const onAddToCart = (product) => {
    addItemToCart(product);
  };

  const updateSelectedConfigValues = (key, value, required) => {
    console.log("=========== value ",value?.sort((a, b) =>
    a?.value.localeCompare(b?.value),
  ))
 
    setSelectedValues((prevState) => {
      const item = prevState.find((item) => item.key == key);
      if (!item) {
        return [
          ...prevState,
          {
            key,
            value,
            required
          },
        ];
      } else {
          return prevState.map((item) => {
            
              if (item.key == key) {
                item.value = value;
              }
            return item;
            
          });
           
        
      }
    });
  };

  return (
      <React.Fragment>
        {/*<p>data {JSON.stringify(item)}</p>*/}
        <div style={{marginBottom:150}}>
        {/*food image*/}
        {item.image &&
        <div
          style={{
            height: 190,
            borderRadius: 5,
            //backgroundColor:COLORS.lightGray2,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop:10
          }}>
            
          <img
            src={item.image}
            style={{height: '100%', width: '100%',borderRadius:20,objectFit:"contain"}}
          />
         

        </div>}

        {/*food description*/}
        <div style={{
            marginTop: SIZES.padding,
          }}>
          <div
            style={{
              display:'flex',
              flexDirection: 'row',
              marginBottom:-15
            }}>
            <h4 style={{width:'70%',textAlign:'center'}}>{item.name}</h4>
            <h4 style={{textAlign:'center'}}>{item.price} Dhs</h4>
          </div>
          <p
            style={{
              color: COLORS.darkGray,
              textAlign:'center'
            }}>
            {item.description}
          </p>
        </div>

        {/*food detail*/}
        {item.customChoice?.map(
                    (customChoice, index) => (
            <div key={index}>
              <CustomRadioButton
                data={customChoice}
                updateSelectedConfigValues={(key, value, required) =>
                  updateSelectedConfigValues(key, value, required)
                }
              />           
            </div>
            )
        )}

        </div>


        {/* actions */}
        <div>
        {
          countOfRequiredReached() &&
        <div style={{
          position: 'absolute',
          bottom:0,
          left: 0,
          right: 0,
          paddingHorizontal: SIZES.padding,
        }}>
        
        <div
          style={{
            display:'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: SIZES.base,
            position:'fixed',
            bottom: 65,
            right: 0,
            left: 0,
            marginLeft: 20,
            marginRight: 20
          }}>
              <>
                <StepperInput
                  containerStyle={{paddingHorizontal: SIZES.base}}
                  value={qty}
                  onPlus={() =>  countOfRequiredReached() && setQty(qty + 1)}
                  onMinus={() =>  countOfRequiredReached() && qty > 1 && setQty(qty - 1)}
                />
                <TextButton
                  buttonContainerStyle={{
                    display:'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 60,
                    borderRadius: SIZES.radius,
                    marginLeft: SIZES.radius,
                    paddingHorizontal: SIZES.padding,
                  }}
                  labelContainerStyle={{
                    width: '70%',
                  }}
                  label="Ajouter au panier"
                  disabled={
                    
                    (!countOfRequiredReached())
                  }
                  label2={qty * getNewPrice() + ' Dhs'}
                  onPress={() => {

                    let customChoices = []
                    console.log("========== selectedValues ",selectedValues)
                    selectedValues.map((element)=>{
                        let newValues = []

                        element.value.map((el)=>{
                            newValues.push(el.value)
                        })

                        customChoices.push({
                          key:element.key,
                          value: newValues
                        })
                    })

                    onAddToCart({
                      ...item,
                      price:getNewPrice(),
                      qty,
                      ...(customChoices.length >= 1 && {
                        categorieConfig: customChoices,
                      }),
                    });
                    navigate("/"+constants.urlqrcode + "/"+currentUser.tableName+'/MyCart/'+constants.suburlqrcode)
                  }}
                />
              </>
            
            
        </div>
        </div>
        }
        </div>
        
        </React.Fragment>
  )
}

const FoodDetail = () => {

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  const {state} = useLocation();
  const {getCurrentUser} = useContext(CartContext)
  const currentUser =getCurrentUser()
  const top = (currentUser && currentUser.tableName) ? 145 : 80

  return <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    
    <div className="container">
    <div className="text-center mt-5 sub_header" style={{paddingHorizontal:'5px',paddingTop:3,top:top}}>
    
<h4 className="" style={{fontWeight: 400,
color: "rgb(102, 102, 102)",marginBottom:0,padding:10}}>

<Link to={(currentUser && currentUser.tableName) ? "/"+constants.urlqrcode + "/"+currentUser.tableName+"/"+constants.suburlqrcode : "/"} style={{
      color: "rgb(102, 102, 102)",
      fontWeight: "600",
      textDecoration: "none",
      marginRight: "10px",
 
      
}}>{/*<img src={back} style={{height: '25px', width: '25px',tintColor:'#666666',borderRadius:20,objectFit:"contain",marginBottom:'5px'}}/>*/}
 RETOUR AU MENU</Link>/ {state.name}</h4>
</div>
      <IndexFoodDetail item={state}/>
    </div>
  </Layout>
}

export default FoodDetail
