import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { CartContext } from './core/CartProvider';
import MyCart from './templates/mycart';
import constants from './constants/constants';

import Category from './templates/category.js';
import FoodDetail from './templates/foodDetail.js';
import App from './pages/homepage.js';
import {useIdleTimer} from 'react-idle-timer';



function MainApp() {

 
  const navigate = useNavigate()
  const location = useLocation()
  const {getCurrentUser, removeAllItems} = useContext(CartContext)
  const currentUser = getCurrentUser()
  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
  if(currentUser && currentUser.name && currentUser.firstName && currentUser.tableName)
  removeAllItems()
  navigate("/")
  }

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  }

  useEffect(()=>{
    if(location.pathname === "/")
      removeAllItems()
  },[location])

  const idleTimer =   useIdleTimer({ onIdle, onActive,timeout:90000 })
  
  return (
    

      <div>
        <Routes>
              <Route exact path="/" element={<App />} />
              <Route path="/details/:categ" element={<Category />} />

              <Route path={constants.urlqrcode + "/:tablename/"+constants.suburlqrcode+"/details/:categ/"} element={<Category />}/>
              <Route path={constants.urlqrcode + "/:tablename/MyCart/"+constants.suburlqrcode} element={<MyCart />}/>
              <Route path={constants.urlqrcode + "/:tablename/FoodDetail/"+constants.suburlqrcode} element={<FoodDetail />}/>

              
              <Route path={constants.urlqrcode + "/:tablename/"+constants.suburlqrcode} element={<App />}>
                <Route path="home" element={<App />} />
              </Route>

              <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
  

  );
}

export default MainApp;
