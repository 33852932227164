import React from "react"
import error from "../images/error.jpg"
import TextButton from "../components/TextButton";

export const ErrorPage = ({title,subtitle,image,buttonLabel,resetError, isLoading}) => {
    return <div style={{flex: 1,
      display:'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 12,
        marginBottom: 50}}>
        <img
          src={ image || error}
          style={{width: '50%', height: '50%',textAlign: 'center',objectFit:'contain'}}
        />
        <h2 style={{marginBottom: 8, marginTop: 20, textAlign: 'center'}}>
          { title || 'Oups !'}
        </h2>
        <h3 style={{padding: 15, textAlign: 'center'}}>
          {subtitle || 'Un problème est survenu. Veuillez réessayer dans quelques instants'}
        </h3>
        {buttonLabel && <TextButton
          buttonContainerStyle={{
            height: 50,
            borderRadius: 10,
            width: "50%",
          }}
          label={buttonLabel || 'Réessayer'}
          onPress={() => {
            resetError();
          }}
          isLoading={isLoading || false}
          disabled={isLoading || false}
        />}
    </div>
}

