/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import minus from "../icons/minus.png"
import { FaFacebook, FaInstagram, FaWhatsapp, FaPlus } from "react-icons/fa"
import { useLocation } from "react-router-dom"



const Layout = ({ children, withFooter, hideAction }) => {

  
  const FloatButton = () => {
    return (
      <Fab
      mainButtonStyles={{backgroundColor:'#d03c39'}}
      icon={<FaPlus/>}
      alwaysShowTitle={true}
      style={{bottom:-25,right:10}}
    >
      // The Action components are the "buttons" that appear when the Fab is open. You can use the out-of-the-box Action
      // component or you can use a custom component of any type and style it any way that you'd like. The "text" prop
      // is the popup label that appears when the Action component is hovered.
      <Action
        text=""
        onClick={()=>console.log("handleEmailOnClick")}
        style={{backgroundColor:'#d03c39e6'}}
      >
        <a href="https://www.facebook.com/HOLA-Blanca-285888555179089/"  target="_blank"><FaFacebook color="white" size={25}/></a>
      </Action>
      <Action
        text=""
        onClick={()=>console.log("handleEmailOnClick")}
        style={{backgroundColor:'#d03c39e6'}}
      >
        <a href="https://www.instagram.com/_hola_blanca_"  target="_blank"><FaInstagram color="white" size={25}/></a>
      </Action>
      <Action
          text=""
          onClick={console.log("handleHelpOnClick")}
          style={{backgroundColor:'#d03c39e6'}}
        >
        <a href="https://wa.me/212672920192"><FaWhatsapp color="white" size={25}/></a>
      </Action>
      
    </Fab>
    )
  }

  return (
 
      <>
        <Header siteTitle={"title"} />
        <div>
          <main>{children}</main>
        </div>
        {!hideAction && <FloatButton/>}
        {withFooter && <Footer />}
      </>

)}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
