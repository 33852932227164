import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainApp from './MainApp.js';

import { QueryClientProvider, QueryClient } from 'react-query';

import reportWebVitals from './reportWebVitals';
import { CartProvider } from './core/CartProvider';
import { BrowserRouter as Router, HashRouter} from 'react-router-dom'
const queryClient = new QueryClient();



const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <QueryClientProvider client={queryClient}>
  <CartProvider>
  <React.StrictMode>
    <HashRouter>
<MainApp/>
</HashRouter>
</React.StrictMode>
</CartProvider>
</QueryClientProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
